import { route } from "quasar/wrappers";
import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes";
import { useAuthStore } from "stores/useAuthStore";

export default route(() => {
  const router = createRouter({
    scrollBehavior(to, _from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else if (to.hash) {
        return {
          el: to.hash,
          top: 64,
          behavior: "smooth",
        };
      } else {
        return { top: 0 };
      }
    },
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createWebHistory(),
  });

  const auth = useAuthStore();

  router.beforeEach((to, from, next) => {
    const userRole = auth.getUser.role ?? 0;
    const accessRole = to.meta.accessRole as number[];

    // 설정된 권한이 없을 경우 통과
    if (!accessRole || !accessRole.length) {
      next();
      return;
    }

    if (accessRole.includes(userRole)) {
      next();
      return;
    }

    alert("허용된 페이지가 아닙니다.");
    next(from);
  });

  return router;
});
